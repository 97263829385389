import { useEffect, useState } from "react";
import axios from "axios";
import { Transacao } from "./typeTransacao";
import { useTransacaoContexto } from "../../contextos/TransacaoContexto";
import { FiltrosType } from "../../telas/transacao/Filtros/Filtros";
import { useContas } from "../contas/useContas";
import { primeiroDiaMesAtual, ultimoDiaMesAtual } from "../../utils/Utils";
import useAvisos from "../../hooks/useAviso";

const API_URL = process.env.REACT_APP_API_URL + "/transacoes";

export function useTransacoes() {
  const { transacoes, setTransacoes } = useTransacaoContexto();

  const { adicionarAviso } = useAvisos();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Função auxiliar para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem("token");

  // Fetch all transacoes with JWT token and optional filters
  const fetchTransacoes = async (filtros?: FiltrosType) => {
    if (loading) {
      return;
    }

    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);

    try {
      // Construir a query string a partir dos filtros fornecidos
      const response = await axios.get<Transacao[]>(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          // Os filtros são passados como query parameters aqui
          tipo: filtros?.tipo,
          dataInicial: filtros?.dataInicial || primeiroDiaMesAtual(),
          dataFinal: filtros?.dataFinal || ultimoDiaMesAtual(),
          descricao: filtros?.descricao,
          valorInicial: filtros?.valorInicial,
          valorFinal: filtros?.valorFinal,
          tipo_pagamento: filtros?.tipo_pagamento,
          conta_id: filtros?.conta,
          categoria_id: filtros?.categoria,
          cartao_credito_id: filtros?.cartao
        }
      });

      setTransacoes(response.data);
    } catch (err) {
      setError("Erro ao buscar as transações.");
    } finally {
      setLoading(false);
    }
  };

  // Create a new transacao with JWT token
  const createTransacao = async (newTransacao: Transacao) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);

    if (newTransacao.tipo_pagamento !== "Cartão de Crédito") {
      delete newTransacao.cartao_credito_id;
    }

    try {
      const response = await axios.post(API_URL, newTransacao, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      //verifica se o retorno é colecao
      if (Array.isArray(response.data)) {
        setTransacoes((prevTransacoes) => [...prevTransacoes, ...response.data]);
      } else {
        setTransacoes((prevTransacoes) => [...prevTransacoes, response.data]);
      }

      adicionarAviso("Movimentação adicionada com sucesso!", "sucesso");
    } catch (err) {
      setError("Erro ao criar a transação.");
    } finally {
      setLoading(false);
    }
  };

  // Update a transacao with JWT token
  const updateTransacao = async (id: number, updatedTransacao: Transacao) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);

    if (updatedTransacao.tipo_pagamento !== "Cartão de Crédito") {
      delete updatedTransacao.cartao_credito_id;
    }

    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedTransacao, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTransacoes((prevTransacoes) => prevTransacoes.map((transacao) => (transacao.id === id ? response.data : transacao)));
      adicionarAviso("Movimentação alterada com sucesso!", "sucesso");
    } catch (err) {
      setError("Erro ao atualizar a transação.");
    } finally {
      setLoading(false);
    }
  };

  // Delete a transacao with JWT token
  const deleteTransacao = async (id: number) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);

    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTransacoes((prevTransacoes) => prevTransacoes.filter((transacao) => transacao.id !== id));
      adicionarAviso("Movimentação removida com sucesso!", "sucesso");
    } catch (err) {
      setError("Erro ao deletar a movimentação.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      adicionarAviso(error, "erro");
      setError(null);
    }
  }, [error]);

  return {
    transacoes,
    loading,
    fetchTransacoes,
    createTransacao,
    updateTransacao,
    deleteTransacao
  };
}

