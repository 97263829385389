import { useRef, useState } from "react";
import styles from "./TextField.module.css";
import { textoParaValor, valorParaTexto } from "../../utils/Utils";

type TextFieldProps = {
  type: string | "text" | "currency" | "phone" | "date" | "number" | "password";
  label: string;
  value?: any;
  onChange: (value: any) => void;
  enabled: boolean;
  autocomplete?: string;
  obrigatorio?: boolean;
  maximo?: number;
  minimo?: number;
};

export default function TextField({
  type = "text",
  label,
  value,
  onChange,
  enabled = true,
  autocomplete,
  obrigatorio = false,
  maximo,
  minimo
}: TextFieldProps) {
  const [onFocus, setOnFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  const handleChange = (inputValue: string) => {
    let validValue = inputValue;

    // Se o tipo for número
    if (type === "number") {
      // Remove caracteres não numéricos
      validValue = validValue.replace(/\D/g, "");

      // Validação do valor mínimo
      if (minimo !== undefined && parseInt(validValue) < minimo) {
        validValue = minimo.toString();
      }

      // Validação do valor máximo
      if (maximo !== undefined && parseInt(validValue) > maximo) {
        validValue = maximo.toString();
      }
    } else {
      // Validação do comprimento máximo para outros tipos
      if (maximo !== undefined && validValue.length > maximo) {
        validValue = validValue.slice(0, maximo);
      }

      // Validação do comprimento mínimo para outros tipos
      if (minimo !== undefined && validValue.length < minimo && validValue.trim() === "") {
        return; // Bloqueia entradas inválidas
      }
    }

    onChange(validValue);
  };

  const formatarValor = (valor?: any) => {
    if (valor === null || valor === undefined) return valor;

    if (type === "currency") {
      const valorNumerico = textoParaValor(valor);
      return valorNumerico !== null ? valorParaTexto(valorNumerico, obrigatorio) : "";
    }

    if (type === "phone") {
      const numerosApenas = valor.replace(/\D/g, "");

      if (numerosApenas.length === 11) {
        return numerosApenas.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
      }

      if (numerosApenas.length <= 10) {
        return numerosApenas.replace(/^(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
      }

      return numerosApenas;
    }

    if (type === "date") {
      if (!valor) return valor;

      if (isNaN(new Date(valor).getTime())) return valor;

      const partes = new Date(valor).toISOString().split("T")[0].split("-");
      return `${partes[0]}-${partes[1]}-${partes[2]}`;
    }

    return valor;
  };

  return (
    <div
      className={styles.container}
      onClick={handleClick}
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
      style={{ backgroundColor: onFocus ? "#cceffa" : "#80d8e314", borderWidth: onFocus ? "2px" : "1px" }}
    >
      <div className={styles.wrapper}>
        <label
          className={styles.label}
          style={{
            color: onFocus ? "#00b0e6" : "#333333",
            top: onFocus || value || obrigatorio || type === "date" ? "calc(50% - 20px)" : "calc(50% - 8px)"
          }}
        >
          <span>{label}</span>
          {obrigatorio && <span style={{ color: onFocus ? "#00b0e6" : "red" }}>*</span>}
        </label>
        <input
          ref={inputRef}
          type={type === "currency" ? "text" : type}
          className={styles.input}
          value={formatarValor(value)}
          onChange={(e) => handleChange(e.target.value)}
          disabled={!enabled}
          autoComplete={autocomplete}
          minLength={minimo}
          maxLength={maximo}
        />
      </div>
    </div>
  );
}

