import styles from "./Carregando.module.css";

type CarregandoProps = {
  loading: boolean;
};

export default function Carregando({ loading }: CarregandoProps) {
  return <div className={styles.container}>{loading ? <div className={styles.skeleton} /> : <p>Não há dados para exibir.</p>}</div>;
}

