import { BrowserRouter, Routes, Route } from "react-router-dom";
import Perfil from "./telas/cadastros/perfil/Perfil";
import Login from "./telas/login/Login";
import CartaoCredito from "./telas/cadastros/cartaoCredito/CartaoCredito";
import Categoria from "./telas/cadastros/categoria/Categoria";
import Dashboard from "./telas/dashboard/Dashboard";
import Conta from "./telas/cadastros/conta/Conta";
import Transacao from "./telas/transacao/Transacao";
import Configuracao from "./telas/configuracao/Configuracao";
import { ContaContexto } from "./contextos/ContaContexto";
import { useState } from "react";
import { Conta as TipoConta } from "./servicos/contas/typeConta";
import { CategoriaContexto } from "./contextos/CategoriaContexto";
import { Categoria as TipoCategoria } from "./servicos/categorias/typeCategoria";
import { TransacaoContexto } from "./contextos/TransacaoContexto";
import { Transacao as TipoTransacao } from "./servicos/transacoes/typeTransacao";
import { PerfilContexto } from "./contextos/PerfilContexto";
import { Perfil as TipoPerfil } from "./servicos/perfis/typePerfil";
import RecuperarSenha from "./telas/login/recuperarSenha/RecuperarSenha";
import CriarConta from "./telas/login/criarConta/CriarConta";
import { AvisoContexto, AvisoType } from "./contextos/AvisoContexto";
import { CartaoCredito as TipoCartao } from "./servicos/cartaoCredito/typeCartaoCredito";
import { CartaoCreditoContexto } from "./contextos/CartaoCreditoContexto";

export default function Rotas() {
  const [contas, setContas] = useState<TipoConta[]>([]);
  const [categorias, setCategorias] = useState<TipoCategoria[]>([]);
  const [transacoes, setTransacoes] = useState<TipoTransacao[]>([]);
  const [perfil, setPerfil] = useState<TipoPerfil>({
    email: "",
    senha: ""
  });
  const [avisos, setAvisos] = useState<AvisoType[]>([]);
  const [cartoes, setCartoes] = useState<TipoCartao[]>([]);

  return (
    <BrowserRouter>
      <PerfilContexto.Provider value={{ perfil, setPerfil }}>
        <ContaContexto.Provider value={{ contas, setContas }}>
          <CategoriaContexto.Provider value={{ categorias, setCategorias }}>
            <TransacaoContexto.Provider value={{ transacoes, setTransacoes }}>
              <AvisoContexto.Provider value={{ avisos, setAvisos }}>
                <CartaoCreditoContexto.Provider value={{ cartoes, setCartoes }}>
                  <Routes>
                    <Route path="/cartoes" element={<CartaoCredito />} />
                    <Route path="/categorias" element={<Categoria />} />
                    <Route path="/contas" element={<Conta />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/recuperar-senha" element={<RecuperarSenha />} />
                    <Route path="/criar-conta" element={<CriarConta />} />
                    <Route path="/perfil" element={<Perfil />} />
                    <Route path="/movimentacoes" element={<Transacao />} />
                    <Route path="/configuracoes" element={<Configuracao />} />
                  </Routes>
                </CartaoCreditoContexto.Provider>
              </AvisoContexto.Provider>
            </TransacaoContexto.Provider>
          </CategoriaContexto.Provider>
        </ContaContexto.Provider>
      </PerfilContexto.Provider>
    </BrowserRouter>
  );
}

