import styles from "./PainelCentral.module.css";

export default function PainelCentral(props: { children: React.ReactNode }) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>{props.children}</div>
    </div>
  );
}

