import { useEffect, useRef } from "react";
import styles from "./Slider.module.css";

type SliderProps = {
  onClose: () => void;
  titulo: string;
  subtitulo: string;
  children: React.ReactNode;
  escurecerFundo?: boolean;
};

export default function Slider({ escurecerFundo = true, ...props }: SliderProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: escurecerFundo ? "rgba(30, 32, 35, .6)" : "transparent",
        backdropFilter: escurecerFundo ? "blur(4px)" : "none"
      }}
    >
      <div className={styles.wrapper} ref={ref}>
        <div className={styles.voltar} onClick={props.onClose}>
          <span className={"material-symbols-rounded"}>arrow_back</span>
          <span>VOLTAR</span>
        </div>
        <div className={styles.cabecalho}>
          <div className={styles.pagina}>
            <span>{props.subtitulo}</span>
          </div>
          <div className={styles.titulo}>
            <span>{props.titulo}</span>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
}

