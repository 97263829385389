import { useState, useEffect, useRef } from "react";
import styles from "./DropDown.module.css";

export type OpcaoDropDown = {
  valor: string;
  texto: string;
};

type DropDownProps = {
  label: string;
  valor?: string;
  onChange: (value: string) => void;
  opcoes: OpcaoDropDown[];
  obrigatorio?: boolean;
};

export default function DropDown({ label, valor, onChange, opcoes, obrigatorio = false }: DropDownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [onFocus, setOnFocus] = useState(false);

  const handleOptionClick = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
      if (valor === "") setOnFocus(false);
    }
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
    setOnFocus(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const textoValor = opcoes.find((opcao) => opcao.valor === valor)?.texto || valor;

  return (
    <div
      className={styles.container}
      onClick={handleClick}
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
      style={{ backgroundColor: onFocus ? "#cceffa" : "#80d8e314", borderWidth: onFocus ? "2px" : "1px" }}
    >
      <div className={styles.wrapper}>
        <label
          className={styles.label}
          style={{ color: onFocus ? "#00b0e6" : "#333333", top: onFocus || textoValor ? "calc(50% - 20px)" : "calc(50% - 8px)" }}
        >
          {label}
          {obrigatorio && <span style={{ color: onFocus ? "#00b0e6" : "red" }}>*</span>}
        </label>
        <div ref={dropdownRef} className={styles.dropdown}>
          <div className={styles.selected}>{textoValor}</div>
          {isOpen && (
            <div className={styles.options}>
              {opcoes.map((opcao) => (
                <div key={opcao.valor} className={styles.option} onClick={() => handleOptionClick(opcao.valor)}>
                  {opcao.texto}
                </div>
              ))}
            </div>
          )}
          <span className={styles.expandir + " material-symbols-rounded"}>{isOpen ? "expand_less" : "expand_more"}</span>
        </div>
      </div>
    </div>
  );
}

