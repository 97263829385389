import { useEffect } from "react";
import { AvisoTipo } from "../../../contextos/AvisoContexto";
import style from "./Aviso.module.css";
import useAvisos from "../../../hooks/useAviso";

type AvisoProps = {
  id: string;
  descricao: string;
  tipo: AvisoTipo;
};

const COLORS = {
  erro: "#cc4400",
  sucesso: "#198000",
  alerta: "#ed9528",
  info: "#0063de"
};

const ICONE = {
  erro: "error",
  sucesso: "check",
  alerta: "warning",
  info: "info"
};

export default function Aviso(props: AvisoProps) {
  const { id, descricao, tipo } = props;

  const { removerAviso } = useAvisos();

  useEffect(() => {
    const timeout = setTimeout(() => {
      removerAviso(id);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div id={id} className={style.container} style={{ background: COLORS[tipo] }} onClick={() => removerAviso(id)}>
      <span className={"material-symbols-rounded " + style.icone}>{ICONE[tipo]}</span>
      {descricao}
    </div>
  );
}

