import styles from "./Formulario.module.css";
import { Categoria } from "../../../../servicos/categorias/typeCategoria";
import TextField from "../../../../componentes/textField/TextField";
import { useEffect, useState } from "react";
import Botao from "../../../../componentes/botao/Botao";
import { useCategorias } from "../../../../servicos/categorias/useCategorias";
import { cores, icones } from "../../../../utils/Listas";
import DropDown from "../../../../componentes/dropdown/DropDown";
import Slider from "../../../../componentes/slider/Slider";

type FormularioProps = {
  categoria: Categoria | null;
  fecharModal: () => void;
  escurecerFundo?: boolean;
};

export default function Formulario({ categoria, fecharModal, escurecerFundo = true }: FormularioProps) {
  const [dados, setDados] = useState({ nome: "", cor: "", icone: "", tipo: "D" });
  const { loading, createCategoria, updateCategoria } = useCategorias();

  const todosPreenchidos = !!(dados.nome && dados.cor);

  const handleSalvar = async () => {
    if (!todosPreenchidos) return;

    const contaAtualizada = {
      nome: dados.nome,
      cor_identificacao: dados.cor,
      tipo: dados.tipo,
      icone: dados.icone
    };

    if (categoria && categoria.id !== undefined) {
      await updateCategoria(categoria.id, contaAtualizada);
    } else {
      await createCategoria(contaAtualizada);
    }

    fecharModal();
  };

  useEffect(() => {
    if (categoria) {
      setDados({
        nome: categoria.nome,
        cor: categoria.cor_identificacao,
        icone: categoria.icone,
        tipo: categoria.tipo
      });
    }
  }, [categoria]);

  return (
    <Slider titulo="Adicionar categoria" subtitulo="Categorias" onClose={() => fecharModal()} escurecerFundo={escurecerFundo}>
      <div className={styles.containerModal}>
        <div className={styles.wrapperModal}>
          <div className={styles.modalCampos}>
            <DropDown
              label="Tipo"
              valor={dados.tipo}
              onChange={(valor) => setDados({ ...dados, tipo: valor })}
              opcoes={[
                { valor: "D", texto: "Despesa" },
                { valor: "R", texto: "Receita" }
              ]}
            />
            <TextField
              type="text"
              label="Nome"
              value={dados.nome}
              onChange={(valor) => setDados({ ...dados, nome: valor })}
              enabled={true}
            />
            <div>
              <div className={styles.modalLabel}>Uma cor para identificar sua categoria</div>
              <div className={styles.modalCores}>
                {cores.map((cor) => (
                  <div
                    key={cor}
                    className={styles.modalCor}
                    style={{
                      backgroundColor: cor,
                      border: dados.cor === cor ? "2px solid #333333" : "2px solid transparent"
                    }}
                    onClick={() => setDados({ ...dados, cor: cor })}
                  ></div>
                ))}
              </div>
            </div>
            <div>
              <div className={styles.modalLabel}>Um icone para identificar sua categoria</div>
              <div className={styles.modalIcones}>
                {icones.map((icone) => (
                  <span
                    key={icone}
                    className={styles.modalIcone + " material-symbols-rounded"}
                    style={{
                      border: dados.icone === icone ? "2px solid #333333" : "2px solid transparent"
                    }}
                    onClick={() => setDados({ ...dados, icone: icone })}
                  >
                    {icone}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.modalBotoes}>
            <Botao
              texto={!loading ? "Salvar" : ""}
              icone={!loading ? "check" : "progress_activity"}
              spin={loading}
              onClick={() => handleSalvar()}
              enabled={todosPreenchidos}
            />
          </div>
        </div>
      </div>
    </Slider>
  );
}

