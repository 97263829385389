import { useState } from "react";
import TextField from "../../componentes/textField/TextField";
import styles from "./Login.module.css";
import Botao from "../../componentes/botao/Botao";
import { useNavigate } from "react-router-dom";
import Logotipo from "../estruturais/menuLateral/logotipo/Logotipo";
import { usePerfis } from "../../servicos/perfis/usePerfil";
import useAvisos from "../../hooks/useAviso";
import Aviso from "../estruturais/aviso/Aviso";

export default function Login() {
  const [dados, setDados] = useState({ email: "", senha: "" });

  const { login } = usePerfis();
  const { avisos, adicionarAviso } = useAvisos();

  const navigate = useNavigate();

  const handleLogin = () => {
    if (dados.email === "") {
      adicionarAviso("Informe o e-mail", "alerta");
      return;
    }

    if (dados.senha === "") {
      adicionarAviso("Informe a senha", "alerta");
      return;
    }

    login(dados.email, dados.senha);
  };

  return (
    <div className={styles.container}>
      <div className={styles.avisos}>
        {avisos.map((aviso) => (
          <Aviso key={aviso.id} id={aviso.id} descricao={aviso.descricao} tipo={aviso.tipo} />
        ))}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <Logotipo />
          <div className={styles.voltar}>
            <span className={"material-symbols-rounded"}>arrow_back</span>
            <span>VOLTAR</span>
          </div>
          <div className={styles.titulo}>Acesse sua conta</div>
          <div className={styles.formulario}>
            <TextField
              label="E-mail"
              value={dados.email}
              onChange={(email) => setDados({ ...dados, email: email })}
              type="text"
              enabled={true}
            />
            <TextField
              label="Senha"
              value={dados.senha}
              onChange={(senha) => setDados({ ...dados, senha: senha })}
              type="password"
              enabled={true}
            />
            <Botao texto="Entrar" onClick={() => handleLogin()} background="#ffd862" color="#333333" />
            <div className={styles.opcoes}>
              <div className={styles.opcao} onClick={() => navigate("/recuperar-senha")}>
                <span>Esqueci minha senha</span>
              </div>
              <div className={styles.opcao} onClick={() => navigate("/criar-conta")}>
                <span>Criar conta</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

