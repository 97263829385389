import styles from "./TipoDespesas.module.css";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Transacao } from "../../../../servicos/transacoes/typeTransacao";
import Quadro from "../../Quadro/Quadro";
import Carregando from "../../Carregando/Carregando";

Chart.register(ArcElement, Tooltip, Legend);

type DashboardProps = {
  loading: boolean;
  transacoes: Transacao[];
};

export default function TipoDespesas({ loading, transacoes }: DashboardProps) {
  // Agrupando transações por categoria
  const transacoesPorTipo = transacoes
    .filter((transacao: Transacao) => transacao.tipo === "D")
    .reduce((acc: any, transacao: Transacao) => {
      const tipo = transacao.tipo_pagamento || "";
      acc[tipo] = acc[tipo] ? acc[tipo] + parseFloat(transacao.valor.toString()) : parseFloat(transacao.valor.toString());
      return acc;
    }, {});

  const retornarCor = (tipo: string) => {
    switch (tipo) {
      case "PIX":
        return "#00c853"; // Verde forte para representar transferências rápidas e digitais
      case "Dinheiro":
        return "#4caf50"; // Verde para representar dinheiro em espécie
      case "Cartão de Crédito":
        return "#1e88e5"; // Azul escuro para um tom sofisticado de crédito
      case "Cartão de Debito":
        return "#29b6f6"; // Azul claro para representar débito de forma mais leve
      case "Boleto":
        return "#fbc02d"; // Amarelo para representar documentos impressos
      case "Transferência":
        return "#00897b"; // Verde-azulado para um tom de transferência bancária
      case "VR":
        return "#d32f2f"; // Vermelho escuro para representar Vale Refeição
      case "VA":
        return "#ff9800"; // Laranja para representar Vale Alimentação
      default:
        return "#9e9e9e"; // Cinza para tipos não especificados
    }
  };

  const tipos = Object.keys(transacoesPorTipo);
  const valores = Object.values(transacoesPorTipo) as number[];

  // Calcula o total das transações
  const totalTransacoes = valores.reduce((sum, valor) => sum + valor, 0);

  // Gerando as cores e descrições das categorias usando o hook
  const cores = tipos.map((tipo) => retornarCor(tipo));
  const descricoes = tipos.map((tipo, index) => {
    const descricao = tipo;
    const percentual = ((valores[index] / totalTransacoes) * 100).toFixed(2); // Calcula o percentual
    return `${descricao} - ${percentual}%`; // Formata a descrição como "nome_da_categoria - XX%"
  });

  const data = {
    labels: descricoes, // Descrições no formato "nome_da_categoria - XX%"
    datasets: [
      {
        data: valores,
        backgroundColor: cores,
        hoverBackgroundColor: cores,
        cutout: "60%" // Donut
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false // Remove as legendas
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const value = valores[tooltipItem.dataIndex];
            // Formata o valor em formato de moeda (BRL)
            const formattedValue = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            });
            return `Total: ${formattedValue}`;
          }
        }
      },
      datalabels: {
        display: false // Esconde os datalabels
      }
    }
  };

  const possuiDados = Object.keys(transacoesPorTipo).length !== 0 && !loading;

  return (
    <div className={styles.container}>
      <Quadro titulo="Fontes das Despesas" rota="/movimentacoes">
        {possuiDados ? (
          <div className={styles.wrapper}>
            <div className={styles.grafico}>
              <Pie data={data} options={options} />
            </div>
            <div className={styles.lista}>
              {tipos.map((tipo, index) => {
                const descricao = tipo || "Outros";
                const valor = valores[index];
                const percentual = ((valor / totalTransacoes) * 100).toFixed(2); // Calcula o percentual
                return (
                  <div key={tipo} className={styles.item}>
                    <div className={styles.categoria}>
                      <div className={styles.categoriaCor} style={{ backgroundColor: cores[index] }}></div>
                      <span>{descricao}</span>
                    </div>
                    <div className={styles.valor}>
                      {valor.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      })}
                    </div>
                    <div className={styles.percentual}>{percentual}%</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Carregando loading={loading} />
        )}
      </Quadro>
    </div>
  );
}

