import { useEffect, useState } from "react";
import styles from "./Cadastro.module.css";
import Formulario from "./Formulario";
import { CartaoCredito } from "../../../../servicos/cartaoCredito/typeCartaoCredito";

type CadastroProps = {
  cartao: CartaoCredito | null;
  setCartao: (cartao: CartaoCredito | null) => void;
};

export default function Cadastro({ cartao, setCartao }: CadastroProps) {
  const [modal, setModal] = useState(false);

  const fecharModal = () => {
    setModal(false);
    setCartao(null);
  };

  useEffect(() => {
    if (cartao) {
      setModal(true);
    }
  }, [cartao]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.botaoAcao} onClick={() => setModal(true)} style={{ background: "#007bff", borderColor: "#007bff" }}>
          <span className="material-symbols-rounded">add</span>
          <span className={styles.textoAcao}>Adicionar Cartão</span>
        </div>
      </div>
      {modal && <Formulario cartao={cartao} fecharModal={fecharModal} />}
    </div>
  );
}

