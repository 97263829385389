import styles from "./Cadastro.module.css";
import { Categoria } from "../../../../servicos/categorias/typeCategoria";
import { useEffect, useState } from "react";
import Formulario from "./Formulario";

type CadastroProps = {
  categoria: Categoria | null;
  setCategoria: (conta: Categoria | null) => void;
};

export default function Cadastro({ categoria, setCategoria }: CadastroProps) {
  const [modal, setModal] = useState(false);

  const fecharModal = () => {
    setModal(false);
    setCategoria(null);
  };

  useEffect(() => {
    if (categoria) {
      setModal(true);
    }
  }, [categoria]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.botaoAcao} onClick={() => setModal(true)} style={{ background: "#007bff", borderColor: "#007bff" }}>
          <span className="material-symbols-rounded">add</span>
          <span className={styles.textoAcao}>Adicionar Categoria</span>
        </div>
        {modal && <Formulario categoria={categoria} fecharModal={fecharModal} />}
      </div>
    </div>
  );
}

