import { useEffect, useState } from "react";
import TextField from "../../../componentes/textField/TextField";
import styles from "./Filtros.module.css";
import DropDown from "../../../componentes/dropdown/DropDown";
import { useContas } from "../../../servicos/contas/useContas";
import { useCategorias } from "../../../servicos/categorias/useCategorias";
import Botao from "../../../componentes/botao/Botao";
import { useCartaoCreditos } from "../../../servicos/cartaoCredito/useCartaoCredito";

export type FiltrosType = {
  tipo?: string;
  dataInicial?: Date;
  dataFinal?: Date;
  descricao?: string;
  valorInicial?: number;
  valorFinal?: number;
  conta?: string;
  cartao?: string;
  categoria?: string;
  tipo_pagamento?: string;
};

const tipoPagamento = [
  { icone: "payments", valor: "PIX", texto: "PIX" },
  { icone: "attach_money", valor: "Dinheiro", texto: "Dinheiro" },
  { icone: "credit_card", valor: "Cartão de Crédito", texto: "Cartão de Crédito" },
  { icone: "credit_card", valor: "Cartão de Debito", texto: "Cartão de Debito" },
  { icone: "receipt", valor: "Boleto", texto: "Boleto" },
  { icone: "account_balance", valor: "Transferência", texto: "Transferência" },
  { icone: "lunch_dining", valor: "VR", texto: "Vale Refeição" },
  { icone: "restaurant", valor: "VA", texto: "Vale Alimentação" }
];
type FiltrosProps = {
  filtros: FiltrosType;
  setFiltros: (filtros: FiltrosType) => void;
  clearFiltros: () => void;
  onClose: () => void;
};

export default function Filtros(props: FiltrosProps) {
  const [filtros, setFiltros] = useState<FiltrosType>({});

  const { contas } = useContas();
  const { categorias } = useCategorias();
  const { cartoes } = useCartaoCreditos();

  const handleLimparFiltros = () => {
    setFiltros({
      tipo: "",
      dataInicial: undefined,
      dataFinal: undefined,
      descricao: "",
      valorInicial: undefined,
      valorFinal: undefined,
      conta: "",
      cartao: "",
      categoria: "",
      tipo_pagamento: ""
    });

    props.clearFiltros();
  };

  const handleFiltrar = () => {
    props.setFiltros({
      ...filtros
    });
  };

  useEffect(() => {
    setFiltros({
      ...props.filtros
    });
  }, [props.filtros]);

  const opcoesCategoria = categorias
    .map((categoria) => {
      return { icone: categoria.icone, valor: categoria.id?.toString() || "", texto: categoria.nome };
    })
    .sort((a, b) => a.texto.localeCompare(b.texto));

  const opcoesConta = contas.map((conta) => {
    return { icone: "", valor: conta.id?.toString() || "", texto: conta.descricao };
  });

  const opcoesCartao = cartoes.map((cartao) => {
    return { icone: "", valor: cartao.id?.toString() || "", texto: cartao.nome };
  });

  return (
    <div>
      <div className={styles.filtros}>
        <div className={styles.filtro}>
          <div className={styles.filtroLinha}>
            <TextField
              label="Descrição da movimentação"
              type="text"
              value={filtros.descricao}
              onChange={(e) => setFiltros({ ...filtros, descricao: e })}
              enabled={true}
            />
          </div>
        </div>
        <div className={styles.filtro}>
          <div className={styles.filtroLinha}>
            <TextField
              label="Data inicial"
              type="date"
              value={filtros.dataInicial || ""}
              onChange={(data) => setFiltros({ ...filtros, dataInicial: data })}
              enabled={true}
              obrigatorio={true}
            />
            <TextField
              label="Data final"
              type="date"
              value={filtros.dataFinal || ""}
              onChange={(data) => setFiltros({ ...filtros, dataFinal: data })}
              enabled={true}
              obrigatorio={true}
            />
          </div>
        </div>
        <div className={styles.filtro}>
          <div className={styles.filtroLinha}>
            <TextField
              label="Valor inicial"
              type="currency"
              value={filtros.valorInicial?.toString()}
              onChange={(valor) => setFiltros({ ...filtros, valorInicial: valor })}
              enabled={true}
            />
            <TextField
              label="Valor final"
              type="currency"
              value={filtros.valorFinal?.toString()}
              onChange={(valor) => setFiltros({ ...filtros, valorFinal: valor })}
              enabled={true}
            />
          </div>
        </div>
        <div className={styles.filtroLinha}>
          <div className={styles.filtro}>
            <div className={styles.opcoes}>
              <div
                className={styles.opcao}
                style={{ backgroundColor: "#198000", color: "#FFFFFF", borderColor: filtros.tipo === "R" ? "#00adff" : "#FFFFFF" }}
                onClick={() => setFiltros({ ...filtros, tipo: "R" })}
              >
                <span>Movimentação de Entrada</span>
              </div>
              <div
                className={styles.opcao}
                style={{ backgroundColor: "#cc4400", color: "#FFFFFF", borderColor: filtros.tipo === "D" ? "#00adff" : "#FFFFFF" }}
                onClick={() => setFiltros({ ...filtros, tipo: "D" })}
              >
                <span>Movimentação de Saida</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filtro}>
          <div className={styles.filtroLinha}>
            <DropDown
              label="Forma de pagamento ou recebimento"
              valor={filtros.tipo_pagamento}
              onChange={(valor) => setFiltros({ ...filtros, tipo_pagamento: valor })}
              opcoes={tipoPagamento}
            />
          </div>
        </div>
        <div className={styles.filtro}>
          <div className={styles.filtroLinha}>
            <DropDown
              label="Banco ou conta"
              valor={filtros.conta}
              onChange={(valor) => setFiltros({ ...filtros, conta: valor })}
              opcoes={opcoesConta}
            />
          </div>
        </div>
        <div className={styles.filtro}>
          <div className={styles.filtroLinha}>
            <DropDown
              label="Cartão de crédito"
              valor={filtros.cartao}
              onChange={(valor) => setFiltros({ ...filtros, cartao: valor })}
              opcoes={opcoesCartao}
            />
          </div>
        </div>
        <div className={styles.filtro}>
          <div className={styles.filtroLinha}>
            <DropDown
              label="Categoria da movimentação"
              valor={filtros.categoria}
              onChange={(valor) => setFiltros({ ...filtros, categoria: valor })}
              opcoes={opcoesCategoria}
            />
          </div>
        </div>
      </div>
      <div className={styles.acoes}>
        <Botao
          texto="Filtrar"
          onClick={handleFiltrar}
          enabled={filtros.dataInicial && filtros.dataFinal ? true : false}
          background="#ffd862"
          color="#333333"
        />
        <Botao texto="Limpar Filtros" onClick={handleLimparFiltros} enabled={true} background="#f5f5f5" color="#333333" />
      </div>
    </div>
  );
}

