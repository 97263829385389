import { createContext, useContext } from "react";
import { CartaoCredito } from "../servicos/cartaoCredito/typeCartaoCredito";

type CartaoCreditoContextType = {
  cartoes: CartaoCredito[];
  setCartoes: React.Dispatch<React.SetStateAction<CartaoCredito[]>>;
};

export const CartaoCreditoContexto = createContext<CartaoCreditoContextType | undefined>(undefined);

export const useCartaoCreditoContexto = () => {
  const context = useContext(CartaoCreditoContexto);
  if (!context) {
    throw new Error("useCartaoCreditoContexto deve ser usado dentro de um CartaoCreditoContexto.Provider");
  }
  return context;
};

