import Pagina from "../estruturais/pagina/Pagina";
import styles from "./Transacao.module.css";
import { useTransacoes } from "../../servicos/transacoes/useTransacoes";
import Tabela, { Coluna } from "../../componentes/tabela/Tabela";
import { primeiroDiaMesAtual, ultimoDiaMesAtual, valorParaTexto } from "../../utils/Utils";
import { useCategorias } from "../../servicos/categorias/useCategorias";
import { useContas } from "../../servicos/contas/useContas";
import { useEffect, useState } from "react";
import Cadastro from "./Cadastro/Cadastro";
import Accordion from "../../componentes/accordion/Accordion";
import useContasUtils from "../../hooks/useContasUtils";
import useCategoriasUtils from "../../hooks/useCategoriasUtils";
import Informativo from "../estruturais/informativo/Informativo";
import ResumoSuperior, { ResumoDash } from "../estruturais/resumoSuperior/ResumoSuperior";
import Filtros, { FiltrosType } from "./Filtros/Filtros";
import Slider from "../../componentes/slider/Slider";
import Registro from "./Registro/Registro";
import useAvisos from "../../hooks/useAviso";
import Carregando from "./Carregando/Carregando";

const colunas: Coluna[] = [
  { header: "Id", accessor: "id", visible: false },
  { header: "", accessor: "tipo", type: "transaction_type", size: "100px", align: "center" },
  { header: "Data", accessor: "data", type: "date", size: "120px", align: "center" },
  { header: "Descricao", accessor: "descricao" },
  { header: "Modo", accessor: "tipo_pagamento", size: "140px", align: "center" },
  { header: "Categoria", accessor: "categoria", type: "text_icon", size: "100px", align: "center" },
  { header: "Valor", accessor: "valor", type: "currency", align: "right", size: "120px" }
];

export default function Transacao() {
  const [abrirFiltros, setAbrirFiltros] = useState(false);
  const [filtros, setFiltros] = useState<FiltrosType>({ dataInicial: primeiroDiaMesAtual(), dataFinal: ultimoDiaMesAtual() });
  const { loading, transacoes, fetchTransacoes } = useTransacoes();
  const { categorias } = useCategorias();
  const { contas } = useContas();
  const { retornarIcone } = useContasUtils();
  const { retornarCor } = useCategoriasUtils();
  const { adicionarAviso } = useAvisos();

  const [selecionada, setSelecionada] = useState<any>(null);

  const handleSelecionar = (row: any) => {
    if (row === null) {
      setSelecionada(null);
      return;
    }

    if (selecionada !== null && selecionada?.id === row?.id) {
      setSelecionada(null);
      return;
    }

    const transacaoOriginal = transacoes.find((transacao) => transacao.id === row.id);
    setSelecionada(transacaoOriginal);
  };

  const handleFiltrar = (filtros: FiltrosType) => {
    const { dataInicial, dataFinal } = filtros;

    if (dataInicial && dataFinal) {
      const diffInTime = new Date(dataFinal).getTime() - new Date(dataInicial).getTime();
      const diffInMonths = diffInTime / (1000 * 60 * 60 * 24 * 30);

      if (diffInMonths > 3) {
        adicionarAviso("O intervalo entre as datas não pode ser maior que 3 meses.", "alerta");
        return;
      }
    } else {
      adicionarAviso("É necessário informar uma data inicial e final.", "alerta");
      return;
    }

    fetchTransacoes(filtros);
    setFiltros(filtros);
    setAbrirFiltros(false);
  };

  const handleLimparFiltros = () => {
    setFiltros({ dataInicial: primeiroDiaMesAtual(), dataFinal: ultimoDiaMesAtual() });
    setAbrirFiltros(false);
    fetchTransacoes();
  };

  const retornarNomeCategoria = (id: number) => {
    const categoria = categorias.find((categoria) => categoria.id === id);
    return categoria ? categoria.nome : "";
  };

  const retornarIconeCategoria = (id: number) => {
    const categoria = categorias.find((categoria) => categoria.id === id);
    return categoria ? categoria.icone : "";
  };

  const retornarNomeConta = (id: number) => {
    const conta = contas.find((conta) => conta.id === id);
    return conta ? conta.descricao : "";
  };

  const retornarValor = (tipo: string, valor: number) => {
    if (tipo === "R") {
      return valor;
    }

    return valor * -1;
  };

  useEffect(() => {
    return () => {
      handleLimparFiltros();
    };
  }, []);

  const dadosParcelados = transacoes.filter((transacao) => transacao.eh_parcelada || (transacao.parcela && transacao.parcela > 1));
  const dadosNaoPareclados = transacoes.filter((transacao) => !transacao.eh_parcelada && transacao.parcela && transacao.parcela === 1);

  const agruparDadosParaVisualizacao = (dados: any) => {
    const dadosOrdenados = [...dados].sort((a, b) => {
      const dataA = new Date(a.data.split("T")[0]).getTime();
      const dataB = new Date(b.data.split("T")[0]).getTime();
      return dataB - dataA;
    });

    const dadosFormatados = [...dadosOrdenados].map((transacao) => {
      const nomeConta = retornarNomeConta(transacao.conta_id);

      let descricaoCompleta = transacao.descricao;

      if (transacao.parcela > 1 || transacao.eh_parcelada) {
        descricaoCompleta += ` (Parcela: ${transacao.parcela})`;
      }

      return {
        id: transacao.id,
        tipo: transacao.tipo,
        data: new Date(transacao.data).toLocaleDateString("pt-BR", { timeZone: "UTC" }),
        descricao: descricaoCompleta,
        tipo_pagamento: transacao.tipo_pagamento,
        categoria:
          retornarIconeCategoria(transacao.categoria_id) +
          "|" +
          retornarNomeCategoria(transacao.categoria_id) +
          "|" +
          retornarCor(transacao.categoria_id),
        conta: retornarIcone(transacao.conta_id) + "|" + nomeConta,
        valor: retornarValor(transacao.tipo, transacao.valor)
      };
    });

    return isMobile ? (
      <div className={styles.lista}>
        {dadosFormatados.map((transacao) => (
          <Registro transacao={transacao} key={transacao.id} selecionada={selecionada} onClick={handleSelecionar} />
        ))}
      </div>
    ) : (
      <Tabela selecionada={selecionada} colunas={colunas} dados={dadosFormatados} onRowClick={handleSelecionar} />
    );
  };

  const totalDespesas =
    transacoes.filter((transacao) => transacao.tipo === "D").reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0) *
    -1;

  const corDespesas = "#df0101";

  const totalReceitas = transacoes
    .filter((transacao) => transacao.tipo === "R")
    .reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0);

  const corReceitas = "#198000";

  const isMobile = window.innerWidth <= 1024;

  return (
    <Pagina icone="currency_exchange" pagina="Movimentações">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Movimentações</div>
            <Cadastro
              transacao={selecionada}
              setTransacao={handleSelecionar}
              abrirFiltros={abrirFiltros}
              setAbrirFiltros={setAbrirFiltros}
            />
            {abrirFiltros && (
              <Slider titulo="Filtros" subtitulo="Movimentações" onClose={() => setAbrirFiltros(false)}>
                <Filtros
                  filtros={filtros}
                  setFiltros={handleFiltrar}
                  clearFiltros={handleLimparFiltros}
                  onClose={() => setAbrirFiltros(false)}
                />
              </Slider>
            )}
          </div>

          <div className={styles.painel}>
            <Informativo>
              <span>Todas as informações apresentadas respeitam os filtros aplicados.</span>
            </Informativo>
            <ResumoSuperior titulo="Resumo financeiro">
              <ResumoDash titulo="Despesas realizadas" cor={corDespesas}>
                {valorParaTexto(totalDespesas, true)}
              </ResumoDash>
              <ResumoDash titulo="Recebidos até o momento" cor={corReceitas}>
                {valorParaTexto(totalReceitas, true)}
              </ResumoDash>
            </ResumoSuperior>

            {loading ? (
              <Carregando loading />
            ) : (
              <div className={styles.listas}>
                {dadosParcelados && (
                  <Accordion nome="Parcelamentos" habilitado={true} expandidoDefault={false}>
                    {<div className={styles.agrupamento}>{agruparDadosParaVisualizacao(dadosParcelados)}</div>}
                  </Accordion>
                )}
                {dadosNaoPareclados && (
                  <Accordion nome="Ultimos Lançamentos" habilitado={false}>
                    {<div className={styles.agrupamento}>{agruparDadosParaVisualizacao(dadosNaoPareclados)}</div>}
                  </Accordion>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Pagina>
  );
}

