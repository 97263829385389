import { createContext, useContext } from "react";
import { Transacao } from "../servicos/transacoes/typeTransacao";

type TransacaoContextType = {
  transacoes: Transacao[];
  setTransacoes: React.Dispatch<React.SetStateAction<Transacao[]>>;
};

export const TransacaoContexto = createContext<TransacaoContextType | undefined>(undefined);

export const useTransacaoContexto = () => {
  const context = useContext(TransacaoContexto);
  if (!context) {
    throw new Error("useTransacaoContexto deve ser usado dentro de um TransacaoContexto.Provider");
  }
  return context;
};

