import { useContas } from "../servicos/contas/useContas";
import { retornarImagemBanco } from "../utils/Utils";

export default function useContasUtils() {
  const { contas } = useContas();

  const retornarDescricao = (id: number) => {
    const descricao = contas.find((conta) => conta.id === id)?.descricao;
    return descricao || "";
  };

  const retornarIcone = (id: number) => {
    const instituicao = contas.find((conta) => conta.id === id)?.instituicao;
    return retornarImagemBanco(instituicao);
  };

  return { retornarDescricao, retornarIcone };
}

