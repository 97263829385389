import { useEffect, useState } from "react";
import Pagina from "../../estruturais/pagina/Pagina";
import styles from "./Perfil.module.css";
import { usePerfis } from "../../../servicos/perfis/usePerfil";
import { Perfil as TypePerfil } from "../../../servicos/perfis/typePerfil";
import Abas from "../../../componentes/abas/Abas";
import Accordion from "../../../componentes/accordion/Accordion";
import TextField from "../../../componentes/textField/TextField";
import DropDown from "../../../componentes/dropdown/DropDown";
import Botao from "../../../componentes/botao/Botao";
import Informativo from "../../estruturais/informativo/Informativo";
import useAvisos from "../../../hooks/useAviso";

const sexo = [
  { valor: "M", texto: "Masculino" },
  { valor: "F", texto: "Feminino" },
  { valor: "O", texto: "Outro" }
];

const estados = [
  { valor: "AC", texto: "Acre" },
  { valor: "AL", texto: "Alagoas" },
  { valor: "AP", texto: "Amapá" },
  { valor: "AM", texto: "Amazonas" },
  { valor: "BA", texto: "Bahia" },
  { valor: "CE", texto: "Ceará" },
  { valor: "DF", texto: "Distrito Federal" },
  { valor: "ES", texto: "Espírito Santo" },
  { valor: "GO", texto: "Goiás" },
  { valor: "MA", texto: "Maranhão" },
  { valor: "MT", texto: "Mato Grosso" },
  { valor: "MS", texto: "Mato Grosso do Sul" },
  { valor: "MG", texto: "Minas Gerais" },
  { valor: "PA", texto: "Pará" },
  { valor: "PB", texto: "Paraíba" },
  { valor: "PR", texto: "Paraná" },
  { valor: "PE", texto: "Pernambuco" },
  { valor: "PI", texto: "Piauí" },
  { valor: "RJ", texto: "Rio de Janeiro" },
  { valor: "RN", texto: "Rio Grande do Norte" },
  { valor: "RS", texto: "Rio Grande do Sul" },
  { valor: "RO", texto: "Rondônia" },
  { valor: "RR", texto: "Roraima" },
  { valor: "SC", texto: "Santa Catarina" },
  { valor: "SP", texto: "São Paulo" },
  { valor: "SE", texto: "Sergipe" },
  { valor: "TO", texto: "Tocantins" }
];

export default function Perfil() {
  const [abas, setAbas] = useState<string[]>(["Dados Pessoais", "Segurança da Conta"]);
  const [abaSelecionada, setAbaSelecionada] = useState<string>(abas[0]);
  const { loading, perfil, updatePerfil } = usePerfis();
  const [dados, setDados] = useState<TypePerfil>(perfil);
  const [novaSenha, setNovaSenha] = useState<string>("");
  const [confirmacaoSenha, setConfirmacaoSenha] = useState<string>("");

  const { adicionarAviso } = useAvisos();

  const handleSalvarInformacoes = async () => {
    if (!perfil || !perfil.id) {
      return;
    }

    await updatePerfil(perfil.id, { ...dados });
  };

  const handleSalvarSenha = async () => {
    if (!perfil || !perfil.id || !novaSenha || !confirmacaoSenha) {
      return;
    }

    if (novaSenha !== confirmacaoSenha) {
      adicionarAviso("Senhas não conferem", "erro");
      return;
    }

    dados.senha = novaSenha;

    await updatePerfil(perfil.id, { ...dados });
  };

  useEffect(() => {
    setDados(perfil);
  }, [perfil]);

  const senhasPreenchidas = novaSenha !== "" && confirmacaoSenha !== "";

  return (
    <Pagina icone="assured_workload" pagina="Perfil">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Perfil</div>
          </div>
          <Informativo>
            <span>Edite suas informações pessoais e de segurança da conta.</span>
          </Informativo>
          <div className={styles.painel}>
            <Abas abas={abas} setAbas={setAbas} abaSelecionada={abaSelecionada} setAbaSelecionada={setAbaSelecionada} />
            {abaSelecionada === "Dados Pessoais" && (
              <div className={styles.formularios}>
                <Accordion nome="Informações Pessoais">
                  <div className={styles.formulario}>
                    <TextField
                      type="text"
                      label="Nome"
                      value={dados.nome}
                      onChange={(value) => setDados({ ...dados, nome: value })}
                      enabled={true}
                    />
                    <DropDown label="Sexo" valor={dados.sexo} onChange={(value) => setDados({ ...dados, sexo: value })} opcoes={sexo} />
                    <TextField
                      type="phone"
                      label="Telefone"
                      value={dados.telefone}
                      onChange={(value) => setDados({ ...dados, telefone: value })}
                      enabled={true}
                    />
                    <TextField
                      type="text"
                      label="Nacionalidade"
                      value={dados.nacionalidade}
                      onChange={(value) => setDados({ ...dados, nacionalidade: value })}
                      enabled={true}
                    />
                  </div>
                </Accordion>

                <Accordion nome="Dados de Localização">
                  <div className={styles.formulario}>
                    <TextField
                      type="text"
                      label="Endereço"
                      value={dados.endereco || ""}
                      onChange={(value) => setDados({ ...dados, endereco: value })}
                      enabled={true}
                    />
                    <TextField
                      type="number"
                      label="Número"
                      value={dados.numero || ""}
                      onChange={(value) => setDados({ ...dados, numero: value })}
                      enabled={true}
                    />
                    <TextField
                      type="text"
                      label="Complemento"
                      value={dados.complemento || ""}
                      onChange={(value) => setDados({ ...dados, complemento: value })}
                      enabled={true}
                    />
                    <TextField
                      type="text"
                      label="Bairro"
                      value={dados.bairro || ""}
                      onChange={(value) => setDados({ ...dados, bairro: value })}
                      enabled={true}
                    />
                    <TextField
                      type="text"
                      label="Cidade"
                      value={dados.cidade || ""}
                      onChange={(value) => setDados({ ...dados, cidade: value })}
                      enabled={true}
                    />
                    <DropDown
                      label="Estado"
                      valor={dados.estado || ""}
                      onChange={(value) => setDados({ ...dados, estado: value })}
                      opcoes={estados}
                    />
                    <TextField
                      type="text"
                      label="País"
                      value={dados.pais || ""}
                      onChange={(value) => setDados({ ...dados, pais: value })}
                      enabled={true}
                    />
                  </div>
                </Accordion>

                <Botao
                  texto={!loading ? "Salvar" : ""}
                  icone={!loading ? "check" : "progress_activity"}
                  spin={loading}
                  onClick={handleSalvarInformacoes}
                />
              </div>
            )}
            {abaSelecionada === "Segurança da Conta" && (
              <div className={styles.formularios}>
                <Accordion nome="Alteração de Senha">
                  <div className={styles.formularioSenha}>
                    <TextField
                      type="password"
                      label="Nova Senha"
                      value={novaSenha}
                      onChange={(value) => setNovaSenha(value)}
                      enabled={true}
                      obrigatorio={true}
                    />
                    <TextField
                      type="password"
                      label="Confirmação de Senha"
                      value={confirmacaoSenha}
                      onChange={(value) => setConfirmacaoSenha(value)}
                      enabled={true}
                      obrigatorio={true}
                    />
                  </div>
                </Accordion>
                <Botao
                  texto={!loading ? "Alterar Senha" : ""}
                  icone={!loading ? "check" : "progress_activity"}
                  spin={loading}
                  enabled={senhasPreenchidas}
                  onClick={handleSalvarSenha}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Pagina>
  );
}

