import Pagina from "../estruturais/pagina/Pagina";
import styles from "./Configuracao.module.css";

export default function Configuracao() {
  return (
    <Pagina icone="settings" pagina="Configurações">
      <div></div>
    </Pagina>
  );
}

