import { useEffect, useState } from "react";
import axios from "axios";
import { Conta } from "./typeConta";
import { useContaContexto } from "../../contextos/ContaContexto";
import useAvisos from "../../hooks/useAviso";

const API_URL = process.env.REACT_APP_API_URL + "/contas";

export function useContas() {
  const { contas, setContas } = useContaContexto();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { adicionarAviso } = useAvisos();

  // Função auxiliar para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem("token");

  // Fetch all contas with JWT token
  const fetchContas = async () => {
    if (loading) {
      return;
    }

    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<Conta[]>(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setContas(response.data);
    } catch (err) {
      setError("Erro ao buscar as contas.");
    } finally {
      setLoading(false);
    }
  };

  // Create a new conta with JWT token
  const createConta = async (newConta: Conta) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(API_URL, newConta, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      response.data.saldo = newConta.saldo;
      response.data.saldoProvisorio = newConta.saldoProvisorio;

      setContas((prevContas) => [...prevContas, response.data]);

      adicionarAviso("Conta criada com sucesso.", "sucesso");
    } catch (err) {
      setError("Erro ao criar a conta.");
    } finally {
      setLoading(false);
    }
  };

  // Update a conta with JWT token
  const updateConta = async (id: number, updatedConta: Conta) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedConta, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setContas((prevContas) =>
        prevContas.map((conta) =>
          conta.id === id ? { ...response.data, saldo: conta.saldo, saldoProvisorio: conta.saldoProvisorio } : conta
        )
      );
      adicionarAviso("Conta atualizada com sucesso.", "sucesso");
    } catch (err) {
      setError("Erro ao atualizar a conta.");
    } finally {
      setLoading(false);
    }
  };

  // Delete a conta with JWT token
  const deleteConta = async (id: number) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setContas((prevContas) => prevContas.filter((conta) => conta.id !== id));
      adicionarAviso("Conta deletada com sucesso.", "sucesso");
    } catch (err) {
      setError("Erro ao deletar a conta.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      adicionarAviso(error, "erro");
      setError(null);
    }
  }, [error]);

  return {
    contas,
    loading,
    error,
    fetchContas,
    createConta,
    updateConta,
    deleteConta
  };
}

