import styles from "./ResumoSuperior.module.css";

type ResumoSuperiorProps = {
  titulo: string;
  children?: React.ReactNode;
};

export default function ResumoSuperior({ titulo, children }: ResumoSuperiorProps) {
  return (
    <div className={styles.resumo}>
      <div className={styles.resumoTitulo}>{titulo}</div>
      <div className={styles.resumoDash}>{children}</div>
    </div>
  );
}

type ResumoDashProps = {
  titulo: string;
  cor: string;
  children: React.ReactNode;
};

export function ResumoDash({ titulo, cor, children }: ResumoDashProps) {
  return (
    <div className={styles.resumoCard}>
      <div className={styles.resumoTexto}>{titulo}</div>
      <div className={styles.resumoValor} style={{ color: cor }}>
        {children}
      </div>
    </div>
  );
}

