import { useEffect, useState } from "react";
import axios from "axios";
import { Categoria } from "./typeCategoria";
import { useCategoriaContexto } from "../../contextos/CategoriaContexto";
import useAvisos from "../../hooks/useAviso";

const API_URL = process.env.REACT_APP_API_URL + "/categorias";

export function useCategorias() {
  const { categorias, setCategorias } = useCategoriaContexto();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { adicionarAviso } = useAvisos();

  // Função auxiliar para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem("token");

  // Fetch all categorias with JWT token
  const fetchCategorias = async () => {
    if (loading) {
      return;
    }

    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<Categoria[]>(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategorias(response.data);
    } catch (err) {
      setError("Erro ao buscar as categorias.");
    } finally {
      setLoading(false);
    }
  };

  // Create a new categoria with JWT token
  const createCategoria = async (newCategoria: Categoria) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      newCategoria.createdAt = new Date().toISOString();
      const response = await axios.post(API_URL, newCategoria, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategorias((prevCategorias) => [...prevCategorias, response.data]);
      adicionarAviso("Categoria criada com sucesso.", "sucesso");
    } catch (err) {
      setError("Erro ao criar a categoria.");
    } finally {
      setLoading(false);
    }
  };

  // Update a categoria with JWT token
  const updateCategoria = async (id: number, updatedCategoria: Categoria) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    updatedCategoria.updatedAt = new Date().toISOString();

    setLoading(true);
    setError(null);
    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedCategoria, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategorias((prevCategorias) => prevCategorias.map((categoria) => (categoria.id === id ? response.data : categoria)));
      adicionarAviso("Categoria atualizada com sucesso.", "sucesso");
    } catch (err) {
      setError("Erro ao atualizar a categoria.");
    } finally {
      setLoading(false);
    }
  };

  // Delete a categoria with JWT token
  const deleteCategoria = async (id: number) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategorias((prevCategorias) => prevCategorias.filter((categoria) => categoria.id !== id));
      adicionarAviso("Categoria deletada com sucesso.", "sucesso");
    } catch (err) {
      setError("Erro ao deletar a categoria.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      adicionarAviso(error, "erro");
      setError(null);
    }
  }, [error]);

  return {
    categorias,
    loading,
    error,
    fetchCategorias,
    createCategoria,
    updateCategoria,
    deleteCategoria
  };
}

