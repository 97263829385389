import { useState } from "react";
import Pagina from "../../estruturais/pagina/Pagina";
import styles from "./Conta.module.css";
import { useContas } from "../../../servicos/contas/useContas";
import Cadastro from "./Cadastro/Cadastro";
import Informativo from "../../estruturais/informativo/Informativo";
import ResumoSuperior, { ResumoDash } from "../../estruturais/resumoSuperior/ResumoSuperior";
import { retornarImagemBanco, valorParaTexto } from "../../../utils/Utils";
import Tabela, { Coluna } from "../../../componentes/tabela/Tabela";
import Accordion from "../../../componentes/accordion/Accordion";

const isMobile = window.innerWidth < 768;

const colunas: Coluna[] = [
  { header: "Id", accessor: "id", visible: false },
  { header: "Banco", accessor: "icone", type: "image", align: "left" },
  { header: "Nome", accessor: "descricao", size: "85%" },
  { header: "Tipo", accessor: "tipo", align: "center", visible: !isMobile },
  { header: "Saldo", accessor: "saldo", align: "center", type: "currency", size: "15%" }
];

export default function Conta() {
  const { contas } = useContas();
  const [selecionada, setSelecionada] = useState<any>(null);

  const totalSaldo = contas.reduce((acc, conta) => acc + parseFloat(conta.saldo?.toString() || "0"), 0);
  const corSaldo = totalSaldo >= 0 ? "#198000" : "#df0101";

  const handleSelecionar = (row: any) => {
    if (selecionada !== null && selecionada?.id === row?.id) {
      setSelecionada(null);
      return;
    }

    setSelecionada(row);
  };

  const dados = contas.map((conta) => {
    return {
      id: conta.id,
      icone: retornarImagemBanco(conta.instituicao),
      descricao: conta.descricao,
      tipo: conta.tipo,
      saldo: valorParaTexto(conta.saldo, true),
      instituicao: conta.instituicao,
      cor_identificacao: conta.cor_identificacao
    };
  });

  return (
    <Pagina icone="assured_workload" pagina="Minhas Contas Bancárias">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Contas Bancárias</div>
            <Cadastro conta={selecionada} setConta={setSelecionada} />
          </div>
          <Informativo>
            <span>Adicione ou edite suas contas bancárias.</span>
          </Informativo>
          <ResumoSuperior titulo="Resumo das contas">
            <ResumoDash titulo="Saldo Total" cor={corSaldo}>
              {valorParaTexto(totalSaldo, true)}
            </ResumoDash>
          </ResumoSuperior>
          <div className={styles.painel}>
            <Accordion nome="Contas">
              <Tabela selecionada={selecionada} colunas={colunas} dados={dados} onRowClick={handleSelecionar} />
            </Accordion>
          </div>
        </div>
      </div>
    </Pagina>
  );
}

