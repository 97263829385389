import { createContext, useContext } from "react";

export type AvisoTipo = "erro" | "sucesso" | "info" | "alerta";

export type AvisoType = {
  id: string;
  descricao: string;
  tipo: AvisoTipo;
};

type AvisoContextType = {
  avisos: AvisoType[];
  setAvisos: React.Dispatch<React.SetStateAction<AvisoType[]>>;
};

export const AvisoContexto = createContext<AvisoContextType | undefined>(undefined);

export const useAvisoContexto = () => {
  const context = useContext(AvisoContexto);
  if (!context) {
    throw new Error("useAvisoContexto deve ser usado dentro de um AvisoContexto.Provider");
  }
  return context;
};

