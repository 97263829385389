import { useCategorias } from "../servicos/categorias/useCategorias";

export default function useCategoriasUtils() {
  const { categorias } = useCategorias();

  const retornarDescricao = (id: number) => {
    const descricao = categorias.find((categoria) => categoria.id === id)?.nome;
    return descricao || "";
  };

  const retornarCor = (id: number) => {
    const cor = categorias.find((categoria) => categoria.id === id)?.cor_identificacao;
    return cor || "#dde5f0";
  };

  const retornarIcone = (id: number) => {
    const icone = categorias.find((categoria) => categoria.id === id)?.icone;
    return icone || "";
  };

  return { retornarDescricao, retornarCor, retornarIcone };
}

