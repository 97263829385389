import styles from "./CheckBox.module.css";

type CheckBoxProps = {
  label: string;
  checked: boolean;
  icon?: string;
  setChecked: (checked: boolean) => void;
  enabled?: boolean;
};

export default function CheckBox({ label, checked, icon, enabled = true, setChecked }: CheckBoxProps) {
  return (
    <div className={styles.container} onClick={() => enabled && setChecked(!checked)} style={{ opacity: enabled ? 1 : 0.5 }}>
      {(icon || label) && (
        <div className={styles.wrapperLabel}>
          {icon && <span className="material-symbols-rounded">{icon}</span>}
          {label && label !== "" && <label className={styles.label}>{label}</label>}
        </div>
      )}
      <input type="checkbox" className={styles.checkbox} checked={checked} />
    </div>
  );
}

