import React, { useMemo, useCallback } from "react";
import styles from "./Tabela.module.css";
import { valorParaTexto } from "../../utils/Utils";

export type Coluna = {
  header: string;
  accessor: string;
  visible?: boolean;
  type?: string;
  size?: string;
  align?: "left" | "right" | "center";
};

type Dados = {
  [key: string]: any;
};

type TabelaProps = {
  selecionada?: Dados;
  colunas: Coluna[];
  dados: Dados[];
  onRowClick?: (row: Dados) => void;
};

const formatarValor = (valor: any, type: string, hint: boolean) => {
  if (valor instanceof Date) {
    return valor.toLocaleDateString();
  }
  if (type === "cor") {
    if (hint) {
      return "";
    }

    return <div style={{ width: "24px", height: "24px", backgroundColor: valor, borderRadius: "50%" }} />;
  }

  if (type === "icone") {
    if (hint) {
      return "";
    }

    return <span className={styles.icone + " material-symbols-rounded"}>{valor}</span>;
  }
  if (type === "currency") {
    if (hint) {
      return valorParaTexto(parseFloat(valor));
    }

    if (valor < 0) {
      return <div style={{ color: "#df0101" }}>{valorParaTexto(parseFloat(valor) * -1)}</div>;
    }
    return <div style={{ color: "#198000" }}>{valorParaTexto(parseFloat(valor))}</div>;
  }

  if (type === "account") {
    const [icon, text, cor] = valor.split("|");

    if (hint) {
      return text;
    }

    return (
      <div className={styles.icone}>
        <img src={icon} alt={text} style={{ backgroundColor: cor || "" }} />
      </div>
    );
  }

  if (type === "text_icon") {
    const [icon, text, cor] = valor.split("|");

    if (hint) {
      return text;
    }

    return (
      <div className={styles.icone + " material-symbols-rounded"} style={{ color: cor || "" }}>
        {icon}
      </div>
    );
  }

  if (type === "transaction_type") {
    if (hint) {
      return valor === "R" ? "ENTRADA" : "SAÍDA";
    }

    return (
      <div className={styles.flexMiddle}>
        <div
          className={styles.transacao}
          style={{
            backgroundColor: valor === "R" ? "#198000" : "#cc4400"
          }}
        >
          {valor === "R" ? <span>ENTRADA</span> : <span>SAÍDA</span>}
        </div>
      </div>
    );
  }

  if (type === "image") {
    if (hint) {
      return "";
    }

    return <img src={valor} alt="Imagem" />;
  }

  return valor;
};

const TabelaRow = React.memo(
  ({
    selecionada,
    row,
    colunas,
    onRowClick
  }: {
    selecionada: boolean;
    row: Dados;
    colunas: Coluna[];
    onRowClick?: (row: Dados) => void;
  }) => {
    const handleClick = useCallback(() => {
      if (onRowClick) {
        onRowClick(row);
      }
    }, [onRowClick, row]);

    return (
      <tr onClick={handleClick} style={{ backgroundColor: selecionada ? "#C5E8F4" : "" }}>
        {colunas
          .filter((coluna) => coluna.visible || coluna.visible === undefined)
          .map((coluna) => (
            <td
              key={coluna.accessor}
              className={styles.td}
              style={{
                width: coluna.size || "",
                textAlign: coluna.align || "left"
              }}
              title={formatarValor(row[coluna.accessor], coluna.type || "", true)}
            >
              {formatarValor(row[coluna.accessor], coluna.type || "", false)}
            </td>
          ))}
      </tr>
    );
  }
);

const Tabela = ({ selecionada, colunas, dados, onRowClick }: TabelaProps) => {
  const renderedHeaders = useMemo(() => {
    return colunas.map((coluna) => (
      <th
        className={styles.th}
        key={coluna.accessor}
        style={{
          display: coluna.visible || coluna.visible === undefined ? "" : "none",
          width: coluna.size || "",
          textAlign: coluna.align || "left"
        }}
      >
        {coluna.header}
      </th>
    ));
  }, [colunas]);

  const renderedRows = useMemo(() => {
    return dados.map((row, idx) => (
      <TabelaRow key={idx} selecionada={selecionada?.id === row.id} row={row} colunas={colunas} onRowClick={onRowClick} />
    ));
  }, [dados, colunas, onRowClick]);

  return (
    <table className={styles.table}>
      <thead>
        <tr>{renderedHeaders}</tr>
      </thead>
      <tbody>{renderedRows}</tbody>
    </table>
  );
};

export default Tabela;

