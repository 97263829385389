import styles from "./Logotipo.module.css";

export default function Logotipo() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logotipo}>
          <span className={styles.infos}>
            <img src={require("../../../../images/logo192.png")} alt="Finant" className={styles.icone} />
          </span>
        </div>
      </div>
    </div>
  );
}

