import { createContext, useContext } from "react";
import { Categoria } from "../servicos/categorias/typeCategoria";

type CategoriaContextType = {
  categorias: Categoria[];
  setCategorias: React.Dispatch<React.SetStateAction<Categoria[]>>;
};

export const CategoriaContexto = createContext<CategoriaContextType | undefined>(undefined);

export const useCategoriaContexto = () => {
  const context = useContext(CategoriaContexto);
  if (!context) {
    throw new Error("useCategoriaContexto deve ser usado dentro de um CategoriaContexto.Provider");
  }
  return context;
};

