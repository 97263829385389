import styles from "./Cadastro.module.css";
import { Transacao } from "../../../servicos/transacoes/typeTransacao";
import { useNavigate } from "react-router-dom";
import Slider from "../../../componentes/slider/Slider";
import FormularioTransacao from "../../cadastros/transacao/Transacao";

type CadastroProps = {
  transacao: Transacao | null;
  setTransacao: (conta: Transacao | null) => void;
  abrirFiltros: boolean;
  setAbrirFiltros: (abrirFiltros: boolean) => void;
};

export default function Cadastro({ transacao, setTransacao, abrirFiltros, setAbrirFiltros }: CadastroProps) {
  const navigation = useNavigate();

  const handleNovo = (tipo: string) => {
    navigation("?lancamento=" + tipo);
  };

  const handleClose = () => {
    setTransacao(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {transacao === null && (
          <div
            className={styles.botaoAcao}
            onClick={() => setAbrirFiltros(!abrirFiltros)}
            style={{ background: "#0063de", borderColor: "#0063de" }}
          >
            <span className="material-symbols-rounded">instant_mix</span>
            <span className={styles.textoAcao}>Filtros</span>
          </div>
        )}
        {transacao === null && (
          <div className={styles.botaoAcao} onClick={() => handleNovo("despesa")} style={{ background: "#cc4400", borderColor: "#cc4400" }}>
            <span className="material-symbols-rounded">trending_down</span>
            <span className={styles.textoAcao}>Adicionar despesa</span>
          </div>
        )}
        {transacao === null && (
          <div className={styles.botaoAcao} onClick={() => handleNovo("receita")} style={{ background: "#198000", borderColor: "#198000" }}>
            <span className="material-symbols-rounded">trending_up</span>
            <span className={styles.textoAcao}>Adicionar receita</span>
          </div>
        )}
        {transacao === null && (
          <div
            className={styles.botaoAcao}
            onClick={() => handleNovo("transferencia")}
            style={{ background: "#6c63ff", borderColor: "#6c63ff" }}
          >
            <span className="material-symbols-rounded">swap_horiz</span>
            <span className={styles.textoAcao}>Transferência entre contas</span>
          </div>
        )}
      </div>
      {transacao && transacao?.tipo === "D" && (
        <Slider titulo="Despesa" subtitulo="Movimentações" onClose={handleClose}>
          <FormularioTransacao transacao={transacao} setTransacao={setTransacao} onClose={handleClose} tipo="D" />
        </Slider>
      )}
      {transacao && transacao?.tipo === "R" && (
        <Slider titulo="Receita" subtitulo="Movimentações" onClose={handleClose}>
          <FormularioTransacao transacao={transacao} setTransacao={setTransacao} onClose={handleClose} tipo="R" />
        </Slider>
      )}
    </div>
  );
}

