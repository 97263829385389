import useContasUtils from "../../../hooks/useContasUtils";
import styles from "./Registro.module.css";

type RegistroProps = {
  transacao: any | null;
  selecionada: any | null;
  onClick: (row: any) => void;
};

export default function Registro({ transacao, selecionada, onClick }: RegistroProps) {
  const categoriaList = transacao.categoria.split("|");
  const contaList = transacao.conta.split("|");

  const corCategoria = categoriaList[2];
  const categoria = categoriaList[1];
  const icone = categoriaList[0];
  const descricao = transacao?.descricao || "";
  const valor = Number(transacao?.valor) || 0;
  const corTipo = transacao?.tipo === "R" ? "#4caf50" : "#f44336";
  const conta = contaList[1];
  const tipo_pagamento = transacao?.tipo_pagamento || "-";

  function calcularCorContraste(hexColor: string) {
    const color = hexColor.replace("#", "");

    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);

    const luminosity = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminosity > 0.5 ? "#000000" : "#FFFFFF";
  }

  return (
    <div
      className={styles.container}
      onClick={() => onClick(transacao)}
      style={{ background: selecionada?.id === transacao.id ? "#c5e8f4" : "#FFFFFF" }}
    >
      <div className={styles.wrapper}>
        <div className={styles.icone} style={{ backgroundColor: corCategoria }} title={categoria}>
          <span className="material-symbols-rounded" color={calcularCorContraste(corCategoria)}>
            {icone}
          </span>
        </div>
        <div className={styles.infos}>
          <div className={styles.titulo}>{descricao}</div>
          <div className={styles.conta}>{tipo_pagamento}</div>
        </div>
        <div className={styles.infosValor}>
          <div className={styles.valor} style={{ color: corTipo }}>
            {Math.abs(valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
          </div>
        </div>
      </div>
    </div>
  );
}

