import Pagina from "../estruturais/pagina/Pagina";
import ResumoCartoes from "./ResumoCartoes/ResumoCartoes";
import ResumoContas from "./ResumoContas/ResumoContas";
import styles from "./Dashboard.module.css";
import Abas from "../../componentes/abas/Abas";
import { useEffect, useState } from "react";
import Informativo from "../estruturais/informativo/Informativo";
import { useTransacoes } from "../../servicos/transacoes/useTransacoes";

export default function Dashboard() {
  const [abas, setAbas] = useState(["Resumo das Contas", "Cartao de Credito"]);
  const [abaSelecionada, setAbaSelecionada] = useState("Resumo das Contas");

  const { fetchTransacoes } = useTransacoes();

  useEffect(() => {
    return () => {
      fetchTransacoes();
    };
  }, []);

  return (
    <Pagina icone="dashboard" pagina="Dashboard">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Dashboard das Contas</div>
          </div>
          <Informativo>
            <span>
              Visualize de forma clara a distribuição dos seus gastos e receitas. Obtenha insights sobre suas finanças com gráficos e
              relatórios personalizados.
            </span>
          </Informativo>
          <Abas abas={abas} setAbas={setAbas} abaSelecionada={abaSelecionada} setAbaSelecionada={setAbaSelecionada} />
          {abaSelecionada === "Resumo das Contas" && <ResumoContas />}
          {abaSelecionada === "Cartao de Credito" && <ResumoCartoes />}
        </div>
      </div>
    </Pagina>
  );
}

