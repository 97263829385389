import { useEffect, useState } from "react";
import axios from "axios";
import { CartaoCredito } from "./typeCartaoCredito";
import useAvisos from "../../hooks/useAviso";
import { useCartaoCreditoContexto } from "../../contextos/CartaoCreditoContexto";

const API_URL = process.env.REACT_APP_API_URL + "/cartoes";

export function useCartaoCreditos() {
  const { cartoes, setCartoes } = useCartaoCreditoContexto();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { adicionarAviso } = useAvisos();

  // Função auxiliar para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem("token");

  // Fetch all cartaoCreditos with JWT token
  const fetchCartaoCreditos = async () => {
    if (loading) {
      return;
    }

    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<CartaoCredito[]>(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCartoes(response.data);
    } catch (err) {
      setError("Erro ao buscar seus cartões de crédito.");
    } finally {
      setLoading(false);
    }
  };

  // Create a new cartaoCredito with JWT token
  const createCartaoCredito = async (newCartaoCredito: CartaoCredito) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(API_URL, newCartaoCredito, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setCartoes((prev) => [...prev, response.data]);

      adicionarAviso("Cartão adicionado com sucesso.", "sucesso");
    } catch (err) {
      setError("Erro ao criar o cartão de crédito.");
    } finally {
      setLoading(false);
    }
  };

  // Update a cartaoCredito with JWT token
  const updateCartaoCredito = async (id: number, updatedCartaoCredito: CartaoCredito) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedCartaoCredito, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCartoes((prev) => prev.map((cartao: CartaoCredito) => (cartao.id === id ? { ...response.data } : cartao)));
      adicionarAviso("Cartão atualizado com sucesso.", "sucesso");
    } catch (err) {
      setError("Erro ao atualizar o cartão de crédito.");
    } finally {
      setLoading(false);
    }
  };

  // Delete a cartaoCredito with JWT token
  const deleteCartaoCredito = async (id: number) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCartoes((prev) => prev.filter((cartao: CartaoCredito) => cartao.id !== id));
      adicionarAviso("Cartão deletado com sucesso.", "sucesso");
    } catch (err) {
      setError("Erro ao deletar o cartão de crédito.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      adicionarAviso(error, "erro");
      setError(null);
    }
  }, [error]);

  return {
    cartoes,
    loading,
    error,
    fetchCartaoCreditos,
    createCartaoCredito,
    updateCartaoCredito,
    deleteCartaoCredito
  };
}

