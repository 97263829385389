import styles from "./Menu.module.css";
import Submenu from "./Submenu";

export default function Menu(props: {
  icone: string;
  nome: string;
  url: string;
  expandido: string;
  submenu: any[];
  handleNavigate: (nome: string, url: string) => void;
}) {
  const menuSelecionado = window.location.pathname === props.url;
  const menuExpandido = props.expandido === props.nome;

  return (
    <div className={styles.container}>
      <div
        className={styles.wrapper + (menuSelecionado ? " " + styles.selecionado : "")}
        onClick={() => props.handleNavigate(props.nome, props.url)}
      >
        <div className={styles.icone + " material-symbols-rounded"}>{props.icone}</div>
        <div className={styles.nome}>{props.nome}</div>
      </div>
      {menuExpandido && props.submenu.length > 0 && (
        <div className={styles.submenu}>
          {props.submenu.map((submenu, index) => (
            <Submenu key={index} icone={submenu.icone} nome={submenu.nome} url={submenu.url} handleNavigate={props.handleNavigate} />
          ))}
        </div>
      )}
    </div>
  );
}

