import { useEffect, useState } from "react";
import styles from "./Formulario.module.css";
import Botao from "../../../../componentes/botao/Botao";
import TextField from "../../../../componentes/textField/TextField";
import DropDown from "../../../../componentes/dropdown/DropDown";
import { useCartaoCreditos } from "../../../../servicos/cartaoCredito/useCartaoCredito";
import { CartaoCredito } from "../../../../servicos/cartaoCredito/typeCartaoCredito";
import Slider from "../../../../componentes/slider/Slider";
import { textoParaValor, valorParaTexto } from "../../../../utils/Utils";

const bandeiras = [
  { icone: "", valor: "Visa", texto: "Visa" },
  { icone: "", valor: "Mastercard", texto: "Mastercard" },
  { icone: "", valor: "Elo", texto: "Elo" },
  { icone: "", valor: "American Express", texto: "American Express" },
  { icone: "", valor: "Diners Club", texto: "Diners Club" },
  { icone: "", valor: "Outros", texto: "Outros" }
];

type FormularioProps = {
  cartao: CartaoCredito | null;
  fecharModal: () => void;
  escurecerFundo?: boolean;
};

export default function Formulario({ cartao, fecharModal, escurecerFundo = true }: FormularioProps) {
  const [dados, setDados] = useState<CartaoCredito>({
    id: undefined,
    limite: 0,
    limiteTexto: "",
    nome: "",
    bandeira: undefined,
    conta_id: undefined,
    data_fechamento: undefined,
    data_vencimento: undefined,
    saldo: undefined
  });
  const { loading, createCartaoCredito, updateCartaoCredito } = useCartaoCreditos();

  const todosPreenchidos = !!(dados.limiteTexto && dados.nome && dados.bandeira && dados.data_fechamento && dados.data_vencimento);

  const handleSalvar = async () => {
    if (!todosPreenchidos) return;

    const regAtualizado = {
      ...dados,
      limite: textoParaValor(dados.limiteTexto)
    };

    if (cartao && cartao.id !== undefined) {
      await updateCartaoCredito(cartao.id, regAtualizado);
    } else {
      await createCartaoCredito(regAtualizado);
    }

    fecharModal();
  };

  useEffect(() => {
    if (cartao) {
      setDados({
        id: cartao.id,
        limite: textoParaValor(cartao.limiteTexto || ""),
        limiteTexto: valorParaTexto(cartao.limite),
        nome: cartao.nome,
        bandeira: cartao.bandeira,
        conta_id: cartao.conta_id,
        data_fechamento: cartao.data_fechamento,
        data_vencimento: cartao.data_vencimento,
        saldo: cartao.saldo
      });
    }
  }, [cartao]);

  return (
    <Slider titulo="Adicionar cartão" subtitulo="Cartões de Credito" onClose={() => fecharModal()} escurecerFundo={escurecerFundo}>
      <div className={styles.containerModal}>
        <div className={styles.wrapperModal}>
          <div className={styles.modalCampos}>
            <DropDown
              label="Bandeira"
              opcoes={[...bandeiras]}
              valor={dados.bandeira}
              onChange={(valor) => setDados({ ...dados, bandeira: valor })}
              obrigatorio={true}
            />
            <TextField
              type="text"
              label="Nome"
              value={dados.nome}
              onChange={(valor) => setDados({ ...dados, nome: valor })}
              enabled={true}
              obrigatorio={true}
            />
            <TextField
              type="currency"
              label="Limite"
              value={dados.limiteTexto}
              onChange={(valor) => setDados({ ...dados, limiteTexto: valor })}
              enabled={true}
              obrigatorio={true}
            />
            <TextField
              type="number"
              label="Dia do fechamento"
              value={dados.data_fechamento}
              onChange={(valor) => setDados({ ...dados, data_fechamento: valor })}
              enabled={true}
              obrigatorio={true}
              maximo={31}
              minimo={1}
            />
            <TextField
              type="number"
              label="Dia do vencimento"
              value={dados.data_vencimento}
              onChange={(valor) => setDados({ ...dados, data_vencimento: valor })}
              enabled={true}
              obrigatorio={true}
              maximo={31}
              minimo={1}
            />
          </div>
          <div className={styles.modalBotoes}>
            <Botao
              texto={!loading ? "Salvar" : ""}
              icone={!loading ? "check" : "progress_activity"}
              spin={loading}
              onClick={() => handleSalvar()}
              enabled={todosPreenchidos}
            />
          </div>
        </div>
      </div>
    </Slider>
  );
}

