import styles from "./UltimasTransacoes.module.css";
import { Transacao } from "../../../../servicos/transacoes/typeTransacao";
import Quadro from "../../Quadro/Quadro";
import Registro from "./Registro";
import { useNavigate } from "react-router-dom";
import Carregando from "../../Carregando/Carregando";

type DashboardProps = {
  loading: boolean;
  transacoes: Transacao[];
};

export default function UltimasTransacoes({ loading, transacoes }: DashboardProps) {
  const navigate = useNavigate();

  const registrosPorDataCriacao = transacoes.sort((a, b) => {
    return new Date(b.createdAt || b.data).getTime() - new Date(a.createdAt || b.data).getTime();
  });

  const registrosTransacoes = registrosPorDataCriacao.slice(0, 5);

  const possuiDados = registrosTransacoes.length > 0 && !loading;

  return (
    <div className={styles.container}>
      <Quadro titulo="Últimos lançamentos" rota="/movimentacoes">
        {possuiDados ? (
          <div className={styles.wrapper}>
            {registrosTransacoes.map((transacao: Transacao, key) => (
              <Registro key={key} transacao={transacao} onClick={() => navigate("/movimentacoes")} selecionada={null} />
            ))}
          </div>
        ) : (
          <Carregando loading={loading} />
        )}
      </Quadro>
    </div>
  );
}

