import { ReactNode } from "react";
import styles from "./Informativo.module.css";

type InformativoProps = {
  children: ReactNode;
};

export default function Informativo({ children }: InformativoProps) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <span className={"material-symbols-rounded " + styles.icone}>info</span>
        <span>{children}</span>
      </div>
    </div>
  );
}

