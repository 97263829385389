import styles from "./Submenu.module.css";

export default function Submenu(props: { icone: string; nome: string; url: string; handleNavigate: (nome: string, url: string) => void }) {
  const menuSelecionado = window.location.pathname === props.url;

  return (
    <div className={styles.container} onClick={() => props.handleNavigate(props.nome, props.url)}>
      <div className={styles.wrapper + (menuSelecionado ? " " + styles.selecionado : "")}>
        <div className={styles.icone + " material-symbols-rounded"}>{props.icone}</div>
        <div className={styles.nome}>{props.nome}</div>
      </div>
    </div>
  );
}

