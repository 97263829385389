import { AvisoTipo, useAvisoContexto } from "../contextos/AvisoContexto";
import { v4 as uuidv4 } from "uuid";

export default function useAvisos() {
  const { avisos, setAvisos } = useAvisoContexto();

  const adicionarAviso = (descricao: string, tipo: AvisoTipo) => {
    const id = uuidv4();
    const aviso = { id, descricao, tipo };
    setAvisos([...avisos, aviso]);
  };

  const removerAviso = (id: string) => {
    setAvisos((avisosAtuais) => avisosAtuais.filter((aviso) => aviso.id !== id));
  };

  return {
    avisos,
    adicionarAviso,
    removerAviso
  };
}

